.signUp {
  max-width: 430px;
  background-color: #fff;
  border-radius: 6px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 0px 40px -24px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 0px 0px 40px -24px rgba(0, 0, 0, 0.38);
  box-shadow: 0px 0px 40px -24px rgba(0, 0, 0, 0.38);
}

.signUp {
  padding: 30px;
  margin-top: 40px;
}

.signUp_title {
  text-align: center;
}

.sign__input-register {
  margin-top: 10px;
}

.signUp_title h3 {
  font-size: 23px;
  line-height: 0;
  margin-bottom: 35px;
  font-weight: 600;
  color: #222;
}

.btn-register {
  background-color: #01579b;
  color: #fff;
  font-size: 16px;
  width: 100%;
  height: 44px;
  border: 1px solid #0277bd;
  font-weight: 600;
  background-image: linear-gradient(#2783df, #16b);
  border-radius: 4px;
  margin-top: 15px;
  cursor: pointer;
}

.btn-register:hover {
  background-image: linear-gradient(#1866b4, #16b);
}

.check__account {
  text-align: center;
}

.check__account h3 {
  color: #222;
  font-size: 16px;
  font-weight: 600;
}

.session__account-link {
  color: #666;
  text-decoration: none;
  font-size: 14px;
}

.message {
  color: green;
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
}

.sign__code {
  max-width: 430px;
  background-color: #fff;
  border-radius: 6px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 0px 40px -24px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 0px 0px 40px -24px rgba(0, 0, 0, 0.38);
  box-shadow: 0px 0px 40px -24px rgba(0, 0, 0, 0.38);
}

.sign__code {
  padding: 30px;
  margin-top: 60px;
}

.signCode_title {
  text-align: center;
  margin-top: -20px;
}

.btn-confirm {
  background-color: #01579b;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  height: 44px;
  background-image: linear-gradient(#2783df, #16b);
  border: 1px solid #0277bd;
  border-radius: 4px;
  margin-top: 15px;
  cursor: pointer;
}

.btn-confirm:hover {
  background-image: linear-gradient(#1866b4, #16b);
}

.error__register {
  color: red;
  font-size: 13px;
  display: block;
}
