body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

label {
  color: #333;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
}

.form-control {
  width: 95%;
  height: 21px;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 8px;
}

.form-control {
  color: inherit;
  color: #666;
  border: 1px solid #dfdfdf;
  padding: 10px;
  font-weight: 400;
}

.call__box {
  text-align: center;
}

.node__root > circle {
  fill: red;
}

.node__branch > circle {
  fill: rgb(53, 175, 53);
}

.node__fallback > circle {
  fill: gray;
}

.container-tree {
  width: "100%";
  height: "100vh";
}

.padre {
  background: yellow;
  height: 150px;
  /*IMPORTANTE*/
  display: flex;
  justify-content: center;
  align-items: center;
}
.containerTree {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.bienvenidaTree {
  width: 40%;
}
.bienvenida {
  display: flex;
}

/*ESTILOS MODAL*/
.paper {
  position: absolute;
  width: 50%;
  height: auto;
  max-height: 500px;
  overflow: auto;
  outline: none;
  background-color: #fff;
}
